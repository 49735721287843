<template>
    <b-container fluid="">
        <b-card no-body class="mb-2 p-1">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="load">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>

        <shipment-confirmation @confirmed="load"></shipment-confirmation>

        <b-table
            :items="items"
            :fields="fields"
            responsive
            head-variant="dark"
        >
            <template #cell(status)="row">
                <b-icon-check-circle-fill
                    v-if="row.item.confirmed"
                    variant="success"
                    :id="row.item.id"
                    title="Отгрузка подтверждена"
                />
                <b-icon-exclamation-circle-fill
                    v-else
                    variant="danger"
                    title="Отгрузка не подтверждена"
                />
            </template>

            <template #cell(actions)="row">
                <b-button
                    @click="downloadWaybill(row.item)"
                    size="sm"
                    variant="success"
                >
                    <b-icon-printer-fill />
                </b-button>
            </template>
        </b-table>

        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="load"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import moment from 'moment';
import {SHIPMENT_ACT_ENDPOINT} from '@utils/endpoints';
import Downloader from '@utils/Downloader';
import ShipmentConfirmation from './ShipmentConfirmation';

export default {
    name: 'ShipmentHistory',
    components: {
        ShipmentConfirmation,
    },
    data() {
        return {
            items: [],
            fields: [
                {
                    key: 'status',
                    label: '',
                    thStyle: {
                        width: '1%',
                    },
                },
                {
                    key: 'created_at',
                    label: 'Дата',
                    formatter(value) {
                        return moment(value).format('DD.MM.YYYY HH:mm');
                    },
                },
                {
                    key: 'timestamp',
                    label: 'Номер накладной',
                },
                {
                    key: 'actions',
                    label: 'Действия',
                    thStyle: {
                        width: '1%',
                    },
                    tdClass: [
                        'text-center'
                    ],
                },
            ],
            meta: {
                current_page: 1,
                total_rows: 0,
                per_page: 25,
            },

        };
    },
    methods: {
        load() {
            this.$http.get(SHIPMENT_ACT_ENDPOINT, {
                params: {
                    page: this.meta.current_page,
                    orders: {
                        created_at: 'desc'
                    }
                }
            }).then((response) => {
                this.items = response.data.data.data;

                this.meta.current_page = response.data.data.current_page;
                this.meta.per_page = response.data.data.per_page;
                this.meta.total_rows = response.data.data.total;
            }).catch((response) => {
                this.$bvToast.toast(response.data.message, {variant: 'danger'});
            });
        },
        downloadWaybill(act) {
            this.$http.get(`${SHIPMENT_ACT_ENDPOINT}/${act.id}/waybill`, {
                responseType: 'arraybuffer',
            }).then((response) => {
                Downloader.download(
                    response.data,
                    'application/pdf',
                    `Накладная ${act.timestamp} на отгрузку ${moment(act.created_at).format('DD.MM.YYYY HH:mm')}.pdf`
                );
            }).catch((response) => {
                this.$bvToast.toast(response.data.message, {variant: 'danger'});
            });
        },
    },
    mounted() {
        this.load();
    }
}
</script>
