<template>
    <div>
        <div v-if="loading" class="alert alert-info text-center"><i class="fas fa-spinner fa-spin"></i></div>
        <template v-else>
            <div v-if="!act" class="alert alert-info text-center">
                Отсканируйте ШК накладной, чтобы начать подтверждение отгрузки
            </div>
            <div v-else-if="act.confirmed" class="alert alert-success text-center">
                Отгрузка уже подтверждена
            </div>
            <div v-else-if="palletBarcodes.length" class="alert alert-info text-center">
                Поочередно отсканируйте ШК всех паллет, указанных в накладной
            </div>
            <div v-else class="alert alert-success text-center">Отгрузка не требует подтверждения</div>
        </template>
    </div>
</template>

<script>
import moment                                             from 'moment';
import Barcode                                            from "@utils/Barcode";
import {SHIPMENT_ACT_ENDPOINT, PRODUCTION_PLANS_ENDPOINT} from "@utils/endpoints";
import {findIndex}                                        from 'lodash';

export default {
    name: "ShipmentConfirmation",
    data() {
        return {
            loading: false,
            act: null,
            confirmedBarcodes: [],
            lastShippedDate: null,
            previousDate: null,
        };
    },
    computed: {
        palletBarcodes() {
            if (!this.act || !this.act.items) {
                return [];
            }

            let existingIndex = -1;
            let barcodes = [];
            this.act.items.map(i => {
                if (!!i.boxContent) {
                    if (!!i.boxContent.palletItems && !!i.boxContent.palletItems[0]) {
                        if (!!i.boxContent.palletItems[0].pallet) {
                            existingIndex = findIndex(barcodes, b => b === i.boxContent.palletItems[0].pallet.code);
                            if (existingIndex === -1) {
                                barcodes.push(i.boxContent.palletItems[0].pallet.code);
                            }
                        }
                    }

                    if (!!i.boxContent.stockItems && !!i.boxContent.stockItems[0]) {
                        existingIndex = findIndex(barcodes, b => b === i.boxContent.stockItems[0].location.code);
                        if (existingIndex === -1) {
                            barcodes.push(i.boxContent.stockItems[0].location.code);
                        }
                    }
                }
            });

            return barcodes;
        }
    },
    methods: {
        onBarcodeScanned(data) {
            if (!this.act && data.barcode.startsWith(Barcode.shipmentActPrefix())) {
                this.actScanned(data.barcode);
                return;
            }

            if (this.act && data.barcode.startsWith(Barcode.palletBarcodePrefix())) {
                this.palletScanned(data.barcode);
                return;
            }

            this.$bvToast.toast('Неверный тип ШК', {variant: 'danger'});
        },
        actScanned(barcode) {
            if (this.loading) {
                this.$bvToast.toast('Дождитесь завершения предыдущей операции', {variant: 'danger'});
                return;
            }

            this.loading = true;
            Promise.resolve()
                .then(() => this.fetchAct(barcode))
                .then(() => this.fetchLastShippedDate(this.act.production_plan_date))
                .catch(error => {
                    if (error) {
                        console.error(error)
                        this.$bvToast.toast(error, {variant: 'danger'});
                    }
                })
                .finally(() => {
                    this.loading = false
                })
        },
        palletScanned(barcode) {
            let index = findIndex(this.palletBarcodes, b => b === barcode);
            let existingIndex = findIndex(this.confirmedBarcodes, b => b === barcode);

            if (index !== -1 && existingIndex === -1) {
                this.confirmedBarcodes.push(barcode);
                let left = this.palletBarcodes.length - this.confirmedBarcodes.length;
                if (left > 0) {
                    this.$bvToast.toast(`Осталось ${left} паллет`, {variant: 'success'});
                }
                this.$forceUpdate();
            }

            if (this.confirmedBarcodes.length === this.palletBarcodes.length) {
                this.confirm();
            }
        },
        confirm() {
            if (this.loading) {
                this.$bvToast.toast('Дождитесь завершения прерыдущей операции', {variant: 'danger'});
                return;
            }

            this.loading = true;
            this.$http.put(SHIPMENT_ACT_ENDPOINT + `/confirm/${this.act.timestamp}`, {
                params: {
                    without_loading: true,
                }
            }).then(response => {
                this.$emit('confirmed', this.act);
                this.$toast.success('Отгрузка подтверждена!');
                this.reset(5);
            }).catch(response => {
                this.$bvToast.toast(response.data.message || 'неизвестная ошибка', {variant: 'danger'});
            }).finally(() => {
                this.loading = false;
            });
        },
        reset(timeout = 0) {
            if (!timeout) {
                this.act = null;
                this.confirmedBarcodes = [];
            } else {
                setTimeout(() => {
                    this.reset();
                }, timeout * 1000);
            }
        },
        fetchAct(barcode) {
            let timestamp = barcode.substr(Barcode.shipmentActPrefix().length)
            return this.$http
                .get(SHIPMENT_ACT_ENDPOINT + `/by_timestamp/${timestamp}`, {
                    params: {
                        without_loading: true,
                        with: [
                            'items.boxContent.palletItems.pallet',
                            'items.boxContent.stockItems.location'
                        ]
                    }
                })
                .then(response => {
                    this.act = response.data;
                    this.$nextTick(() => {
                        if (!this.palletBarcodes.length || this.act.confirmed) {
                            this.reset(5)
                        }
                    })
                }, response => {
                    return Promise.reject('Ошибка: ' + (response.data.message || 'неизвестная ошибка'))
                })
        },
        fetchLastShippedDate(currentDate) {
            return this.$http
                .get(PRODUCTION_PLANS_ENDPOINT + '/last-shipped-date', {
                    params: {
                        current_date: moment(currentDate).format('YYYY-MM-DD'),
                        without_loading: true
                    }
                })
                .then(response => response.data, response => {
                    return Promise.reject('Не удалось загрузить дату последней отгрузки');
                })
                .then(({lastShippedDate, previousDate, currentDate}) => {
                    lastShippedDate = moment(lastShippedDate).startOf("day")
                    previousDate = moment(previousDate).startOf("day")
                    currentDate = moment(currentDate).startOf("day")
                    if (lastShippedDate < previousDate) {
                        this.showLastShippedDate(previousDate, currentDate)
                        return Promise.reject(null)
                    }
                })
        },
        showLastShippedDate(previousDate, currentDate) {
            this.$toast.error(
                [
                    `Вы пытаетесь отгрузить продукцию на ${currentDate.format('DD.MM')}, но отгрузочный день ${previousDate.format('DD.MM')} не завершен.`,
                    `Вернитесь в интерфес отгрузки и завершите отгрузочный день ${previousDate.format('DD.MM')}.`,
                ].join('<br />'),
                '',
                {
                    timeout: false,
                    buttons: [
                        [
                            `<button class="btn btn-danger">Вернуться</button>`,
                            (instance, toast) => {
                                this.goToLastShippedDate(previousDate)
                            }
                        ]
                    ]
                }
            )
        },
        goToLastShippedDate(previousDate) {
            this.$router.push({
                name: "ShipmentTaskList",
                params: {
                    date: previousDate
                }
            })
        }
    },
    mounted() {
        this.$root.listenToBarcodeScanning(this);
    }
}
</script>
